import { HeadProps } from "gatsby";
import React from "react";
import FormPageWrapper from "../components/FormPageWrapper";
import Layout from "../components/Layout";
import Seo from "../features/seo/Seo";

const Contact = () => {
  return (
    <Layout>
      <FormPageWrapper title="Contact">
        <p className="mb-1">
          For billing related questions please{" "}
          <a
            className="text-blue-700 hover:underline hover:text-blue-600"
            href="https://paddle.net"
            target="_blank"
          >
            get in touch
          </a>{" "}
          with our payments processor Paddle.
        </p>
        <p>
          For any other questions or comments please send us an email at{" "}
          <a
            className="text-blue-700 hover:underline hover:text-blue-600"
            href="mailto:learn@giancolianswers.com"
          >
            learn@giancolianswers.com
          </a>
        </p>
      </FormPageWrapper>
    </Layout>
  );
};

export default Contact;

export const Head = (props: HeadProps) => {
  return (
    <Seo
      title="Contact | Giancoli Answers"
      pathname={props.location.pathname}
    />
  );
};
